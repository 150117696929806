import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initializeFolderLink()
  }

  initializeFolderLink() {
    this.element.addEventListener('click', (e) => {
      if (e.target.closest('.folder-link')) {
        e.preventDefault()
        const folderLink = e.target.closest('.folder-link')
        console.log('Lien du dossier cliqué')
        const folderPath = folderLink.getAttribute('data-folder-path')
        console.log('Chemin du dossier:', folderPath)

        // Envoi du message à l'extension via window.postMessage
        window.postMessage({ type: 'OPEN_FOLDER', folderPath: folderPath }, '*')
      }
    })
  }
}
